import { useState, useEffect, createRef } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "./index.css";
import data from "./toulouse.json";
import stops from "./tisseo/stops.json";

// Création de l'icône personnalisée
const customIcon = new L.Icon({
  iconUrl:
    "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});

const customIconBlue = new L.Icon({
  iconUrl:
    "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});

const customIconYellow = new L.Icon({
  iconUrl:
    "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-yellow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});

function Map() {
  const [stations, setStations] = useState([]);
  const mapRef = createRef();
  const [showBikeStations, setShowBikeStations] = useState(false);
  const [showMetroStations, setShowMetroStations] = useState(false);

  const containerBounds = [
    [43.6, 1.4], // south-west coordinates
    [43.7, 1.6], // north-east coordinates
  ];

  useEffect(() => {
    setStations(data);
  }, []);

  const handleZoomIn = () => {
    const map = mapRef.current;
    map.zoomIn();
  };

  const handleZoomOut = () => {
    const map = mapRef.current;
    map.zoomOut();
  };

  return (
    <div>
      <div id="map-container" className="map-container">
        <MapContainer
          ref={mapRef}
          center={[43.60043118473281, 1.4444583539753575]}
          zoom={13}
          scrollWheelZoom={true}
          zoomControl={false}
          bounds={containerBounds}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

          {showBikeStations &&
            stations.map((station) => (
              <Marker
                key={station.number}
                position={[station.latitude, station.longitude]}
                icon={customIcon}
              >
                <Popup>
                  <div>
                    <h3>{station.name}</h3>
                    <p>
                      Aucune donnée en temps réel n'est disponible pour le
                      moment.
                    </p>
                  </div>
                </Popup>
              </Marker>
            ))}
          {showMetroStations &&
            stops.map((stop) => {
              if (stop.stop_code !== "") {
                return null;
              }
              return (
                <Marker
                  key={stop.stop_id}
                  position={[stop.stop_lat, stop.stop_lon]}
                  icon={
                    stop.stop_code === "" ? customIconBlue : customIconYellow
                  }
                >
                  <Popup>
                    <div>
                      <h3>{stop.stop_name}</h3>
                      <p>{stop.stop_code}</p>
                    </div>
                  </Popup>
                </Marker>
              );
            })}
        </MapContainer>
        <div className="leaflet-custom-zoom">
          <button onClick={handleZoomIn}>+</button>
          <button onClick={handleZoomOut}>-</button>
        </div>
      </div>
      <div>
        <div>
          <input
            type="checkbox"
            checked={showBikeStations}
            onChange={(e) => setShowBikeStations(e.target.checked)}
          />
          <label>Afficher les stations de vélo</label>
        </div>
        <div>
          <input
            type="checkbox"
            checked={showMetroStations}
            onChange={(e) => setShowMetroStations(e.target.checked)}
          />
          <label>Afficher les points d'intérêt</label>
        </div>
      </div>
    </div>
  );
}

export default Map;
