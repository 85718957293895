import React from "react";
import ReactDOM from "react-dom";
import Home from "./pages/Home";
import Header from "./components/Header";
import Survey from "./pages/Survey";
import Error from "./components/Error";
import Results from "./pages/Results";
import Freelances from "./pages/Freelances";
import Map from "./pages/TransportData";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Router>
      <Header />
      <Routes>
        <Route path="*" element={<Error />} />
        <Route exact path="/" element={<Home />} />
        <Route exact path="/survey/:questionNumber" element={<Survey />} />
        <Route exact path="/results" element={<Results />} />
        <Route exact path="/freelances" element={<Freelances />} />
        <Route exact path="/datas" element={<Map />} />
      </Routes>
    </Router>
  </React.StrictMode>
);
