import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

function Survey() {
  const { questionNumber } = useParams();
  return (
    <div>
      <h1>Questionnaire ❓</h1>
      <h2>Question : {questionNumber}</h2>
      {parseInt(questionNumber) > 1 ? (
        <a href={parseInt(questionNumber) - 1}>Précédent</a>
      ) : null}
      {parseInt(questionNumber) < 10 ? (
        <a href={parseInt(questionNumber) + 1}>Suivant</a>
      ) : (
        <Link to="/Results">Results</Link>
      )}
    </div>
  );
}
export default Survey;
